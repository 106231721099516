.catalog-map-item {
  text-align: center;

  &,
  &:hover {
    text-decoration: underline;
  }

  &__image {
    height: 162px;
    margin-bottom: 10px;
    position: relative;
  }

  &__img {
    @include absolute-center-translate;
    max-height: 100%;
  }

  &__caption {

  }
}