.page {

  &__footer {
    padding: 30px 0;
    overflow: hidden;
  }

  &__container {
    max-width: $site-max-width;
  }

  &__breadcrumbs {
    padding: 15px 0;
  }
}