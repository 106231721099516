.pc-add-to-cart {
  width: 100%;

  @media (min-width: $screen-xs){
    width: auto;
  }

  &__quantity,
  &__button {
    @media (min-width: $screen-xs){
      margin-bottom: 0;
    }
  }

  &__quantity {
    width: 100px;
    max-width: 100px;

  }

  &__button {

  }

  .added_to_cart {
    color: var(--btn-default-color);
    background: var(--btn-default-bg);
    border-color: var(--btn-default-border);

    &:hover {
      color: var(--btn-default-hover-color);
      background: var(--btn-default-hover-bg);
      border-color: var(--btn-default-hover-border);
    }
  }
}