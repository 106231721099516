.product-thumb {

  &__left {
    border: 1px solid var(--base-border-color);
    width: 80px;
  }

  &__title {
    font-size: .9rem;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }

  &__row:not(:first-child) {
    margin-top: 5px;
  }

  @at-root .product-kit &__left {
    width: 80px;
  }

  @at-root .product-kit .product-photo__item {
    height: 80px;
    padding-bottom: 0;
    padding: 5px;
  }

  @at-root .product-kit & .product-photo__img {
    padding: 0;
  }

  &__row:not(.product-price) {
    font-size: 0.9rem;
  }
}