.star-voting {

  &__ico {

    .svg-icon {
      stroke-width: 40;

      color: var(--star-empty-color);
      stroke: var(--star-rating-color);
    }
  }

  &__ico:hover,
  &__ico:hover ~ &__ico,
  &__input:checked ~ &__ico {
    .svg-icon {
      color: var(--star-rating-color);
    }
  }
}