.small-post {
  position: relative;

  @at-root .widget-sidebar &__image {
    float: left;
    width: 35%;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  &__title {
    font-size: 1rem;

    a,
    a:hover {
      text-decoration: underline;
    }
  }

  &__desc {
    padding-top: 5px;
    font-size: 0.9rem;
  }

  &__date {
    font-size: 0.9rem;
  }

  @at-root .widget-sidebar &__item {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px dashed var(--base-border-color);
  }


}