.pc-category-products-layout {

  &__row {
    //reset parent style
    margin: 0;


    //new style
    @media (min-width: $screen-sm-min){
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__sidebar,
  &__body {
    //reset parent style
    padding: 0;
    float: none;
  }

  &__sidebar {
    @media (min-width: $screen-sm-min){
      width: $page-sidebar-width;
      margin-right: 30px;
    }
  }

  &__body {
    @media (min-width: $screen-sm-min){
      width: calc(100% - #{$page-sidebar-width} - 30px);
    }
  }
}