.product-photo {

  &__item {
    padding-bottom: 75%;
  }

  @at-root .widget-sidebar &__img {
    padding: 3px;
  }

  @at-root .widget-sidebar &__item {
    padding-bottom: 100%;
  }

}