.pc-product-action {

  @at-root .product-cut &--stock-html {
    order: 1!important;
  }

  &__ico {
    color: var(--theme-main-color);

    &--compare {
      width: 20px;
    }
  }
}