.product-cut {
  text-align: left;
  
  &__extra-info {
    background: #fff;
    border: 2px solid var(--theme-main-color);
    border-top: 0;

    & > .variants-select {
      order: 1;
    }

    & > * {
      order: 3;
    }
  }

  &:hover &__extra-info {
    @media (min-width: $screen-md-min){
      display: flex;
      flex-direction: column;
    }
  }

  &__action-row {
    order: 2;
    justify-content: flex-start;

  }

  @media (min-width: $screen-md-min){
    &:hover:before {
      border: 2px solid var(--theme-main-color);
      border-bottom: 0;
    }
  }

  &__title a {
    text-decoration: underline;
  }
}