.quantity {

  &--single-product &__input {
    border-color: var(--form-conrols-border-color);
  }

  &__btn {
    display: none;
  }

  &__input {
    -moz-appearance: initial;
    -webkit-appearance: initial;

    &::-webkit-inner-spin-button {
      display: inline;
    }
  }
}