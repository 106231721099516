.pc-product-summary {
  background: var(--panels-bg-color);
  padding: 28px;

  .product_title {
    font-size: 1.428rem;
    font-weight: bold;
  }

  &__footer > *{
    padding-top: 15px;
    border-top: 1px dotted #c5c5c5;
  }

  &__more-link {
    padding-top: 15px;
  }
}