.cart-summary {

  &__delete .remove_from_cart_button {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.3333333;
    border-radius: 2px;
    border: 1px solid;
    width: auto;
    height: auto;


    text-align: center;

    color: var(--btn-default-color);
    background: var(--btn-default-bg);
    border-color: var(--btn-default-border);

    &:hover {
      color: var(--btn-default-hover-color);
      background: var(--btn-default-hover-bg);
      border-color: var(--btn-default-hover-border);
    }
  }

  &__total-price--order {
    padding: 0;
    background: transparent;
  }
}
