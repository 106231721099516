.footer {
  font-size: .9rem;

  a,
  .footer__widget .menu-item a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__widget-title {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  & &__widget ul li.menu-item {
    margin-bottom: 10px;
  }


  &__row {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max){
      justify-content: space-between;
    }
  }

  &__col {
    @media (max-width: $screen-md-max){
      max-width: 250px;
      width: auto;
      //flex-grow: 1;
    }

    @media (min-width: $screen-lg-min){
      &:first-child {
        position: relative;
        & > * {
          position: relative;
          z-index: 10;
        }
        &:before {
          content: '';
          position: absolute;
          top: -30px;
          bottom: 0;
          right: 0;
          width: 1000px;
          background: var(--footer-aside-bg-color);
          z-index: 1;
        }
      }
    }

    &:nth-child(2) ~ *{
      @media (min-width: $screen-lg-min){
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      position: relative;
      @media (min-width: $screen-sm-min){
        &:before {
          position: absolute;
          content: '';
          width: 0;
          height: calc(100% - 30px);
          border-left: 1px dashed var(--footer-border-color);
          left: 0;
          top: 0;
        }
      }
    }
  }
}