.pagination {

  &__item {
    @media (min-width: $screen-sm-min){
      height: 3.42rem;
      width: 3.42rem;
      line-height: 3.42rem;
    }
    .page-numbers {
      border: 1px solid var(--base-border-color);
    }

    a.page-numbers,
    a.page-numbers:hover {
      color: #666;
    }
    a.page-numbers:hover {
      background: var(--panels-bg-color);
    }
  }
  .page-numbers.current {
    background: #666;
    border: 1px solid #666;
  }

  .page-numbers {
    &:before {
      color: #666;
      font: normal normal normal 14px FontAwesome;
      line-height: 26px;
      font-size: 2rem;
      @include absolute-center-translate;
    }
  }

  .next.page-numbers,
  .prev.page-numbers {
    font-size: 0;
  }

  .prev.page-numbers:before {
    content: "\f104";
  }
  .next.page-numbers:before {
    content: "\f105";
  }
}


