.product-labels {

  &__item {

    width: 4.3em;
    height: 2.2em;

    &--hit {
      color: #fff;
      background-color: rgba(119,15,95,.5);
    }
    &--discount {
      color: #fff;
      background-color: rgba(196,80,35,.5)
    }
  }

  &__text {
    text-transform: none;
  }
}