.metaslider {

  // Type Flex
  &.metaslider-flex {

    .flex-control-nav {
      li {
        a {

          background: transparent;
          border: 1px solid var(--base-border-color);
          &.flex-active {
            background: var(--base-border-color);
          }
        }
      }
    }
    // arrows
    .flex-direction-nav {

      a.flex-prev,
      a.flex-next {
        width: 40px;
        height: 100%;
        top: 0;
        transform: none;
        border-radius: 0;
        background: transparent;
        @media (min-width: $screen-md-min){
          background-color: rgba(0,0,0,.129);
          &:hover {
            background: rgba(0,0,0,.2);
          }

        }

        &:before {
          @include absolute-center-translate;
          font-size: 2rem;
        }
      }

      .flex-prev {
        left: 0!important;
        &:before {
          content: "\f104"!important;
          right: 2px;
        }
      }
      .flex-next {
        right: 0!important;
        &:before {
          content: "\f105"!important;
          left: 2px;
        }
      }


    }
  }// Type Flex END


}




