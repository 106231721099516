.widget-sidebar {
  position: relative;

  &__header {
    background: transparent;
    padding: 0;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 400;
  }


  // Readmore link
  .pc-section-secondary__viewall {
    display: block;
    position: absolute;
    bottom: -30px;
    left: 0;
    &:before {
      content: none;
    }
    a {
      position: relative;
      padding-right: 10px;
      display: inline-block;
      font-weight: 400;
      font-size: 0.9rem;
      text-decoration: underline;
      &:after {
        content: '>';
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  @at-root .widget_products &__inner,
  &--related-products &__inner {
    padding: 25px 20px;
    border: 1px solid var(--base-border-color);
  }
}