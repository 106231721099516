.frame {

  &--checkout-order &__header {
    background: transparent;
    padding: 0 0 15px 0;
    margin: 0;
  }

  &--checkout-order &__title h3,
  &--checkout-order &__title {
    color: #000;
    font-size: 1.6rem;
    font-weight: normal;
  }

  &--checkout-order &__inner {
    border: 1px solid var(--base-border-color);
    padding: 20px;
  }
}

