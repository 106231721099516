.content__sidebar .pc-active-filter {

  &__list {
    border: 1px solid var(--base-border-color) ;
  }

  &__item-link {
    color: var(--base-main-link-color);
    &:hover {
      color: var(--base-main-link-hover-color);
    }
  }
}