.modal {

  &__header {
    padding: 22px 25px 21px;
    background: #fff;
  }

  &__header-title {
    font-weight: bold;
    font-size: 1.571rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 20px;
  }

  &__header-close {
    border-left: none;
  }

  &__header-close-ico {
    width: 24px;
    height: 24px;
    margin-right: 25px;

    &:hover svg {
      color: var(--base-main-link-color);
    }
  }

  &--lg {
    max-width: 890px;
  }

  &--quick-view {
    max-width: 950px;
  }

  &__content {
    padding: 15px 25px;
  }

  &__footer {
    background: #fff;
    border: none;
    padding: 25px 25px 15px;

    .btn {
      @media (min-width: $screen-lg-min){
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 2px;
      }
    }
  }
}