//Link primary
@mixin link-main {
  color: $base-main-link-color;
  text-decoration: $base-main-link-decor;

  &:hover {
    color: $base-main-link-hover-color;
    text-decoration: $base-main-link-decor-hover;
  }
}

//Link second
@mixin link-second {
  color: $base-second-link-color;
  text-decoration: $base-second-link-decor;

  &:hover {
    color: $base-second-link-hover-color;
    text-decoration: $base-second-link-decor-hover;
  }
}

@mixin link-js {
  text-decoration: none;
  border-bottom-style: dashed;
  border-bottom-width: 1px;

  &:hover {
    text-decoration: none;
  }
}

//Link empty, not clickable
@mixin link-empty {
  &, &:hover {
    color: $base-font-color !important;
    text-decoration: none;
    cursor: default;
    pointer-events: none;
  }
}

@mixin grid-vetical-gutter($gutter) {
  margin-bottom: -$gutter;

  & > *,
  .slick-track > [class*="col-"]{
    padding-bottom: $gutter;
  }
}

//vertical alignment
@mixin absolute-center-translate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// absolute vertical and horizontal align. elemet's height should be set
@mixin absolute-center-margin {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

/* Text meant only for screen readers */
@mixin screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

@mixin wp-widget-link-list($icon){
  ul {

    li {
      &:not(:last-child){
        margin-bottom: 10px;
      }

      @if $icon != '' {
        padding-left: 22px;
        position: relative;

        &:before {
          font: normal normal normal 14px/1 FontAwesome;
          content: $icon;
          color: $widget-icon-color;
          position: absolute;
          left: 0;
          top: 2px;
        }
      }
      a {
        @include link-second;
      }
    }

  }

  // Dropdown style
  select {
    @extend .form-control , .input-sm;
  }

  .post-date {
    display: block;
  }
}


@mixin woo-star-rating(){
  .star-rating {
    display: inline-block;
    height: 1em;
    line-height: 1;
    overflow: hidden;
    position: relative;
    width: 5.3em;
    font-weight: 400;

    &:before {
      font: normal normal normal 14px/1 FontAwesome;
      content: '\f005\f005\f005\f005\f005';
      color: $star-empty-color;
      font-size: 16px;
    }

    span {
      overflow: hidden;
      float: left;
      top: 0;
      left: 0;
      position: absolute;
      padding-top: 1.5em;
      &:before {
        color: $star-rating-color;
        font: normal normal normal 14px/1 FontAwesome;
        content: '\f005\f005\f005\f005\f005';
        top: 0;
        position: absolute;
        left: 0;
        font-size: 16px;
      }
    }
  }
}

@mixin btn-default-style {
  text-decoration: none;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 1rem;
  line-height: 1.428571429;
  border-radius: $btn-base-border-radius;
  user-select: none;

  &:active {
    box-shadow: $btn-active-box-shadow;
  }
}

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

@mixin overlay($z-index){
  display: block;
  @include position(fixed, 0 0 0 0);
  z-index: $z-index;
  background-color: $page-overlay-color;
  animation: fade 500ms;

  @keyframes fade {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
}

// We use this to do clear floats
@mixin clearfix {
  &:before, &:after { content: " "; display: table; }
  &:after { clear: both; }
}