.product-kit {

  &__item {
    justify-content: flex-start;
  }

  &__purchase {
    flex-shrink: 0;
  }

  &__purchase-inner {
    background: transparent;
    padding: 0;
    @media (min-width: $screen-md-min){
      text-align: left;
    }
  }

  &__products {
    flex-grow: 1;
  }

  &__slide:not(:last-child) {
    margin-bottom: 30px;
  }

  &__product {
    width: 280px;
  }

  &__product:not(:first-child):before {
    color: var(--base-font-color-secondary);
  }

  &__discount-label {
    background-color: rgba(119,15,95,.5);
    width: 4.3em;
    height: 2.2em;
    top: 4px;
    left: 5px;
  }


  &__price {
    padding-bottom: 0;
  }

  &__discount {
    padding-bottom: 0;
  }
}