.product-price{

  &--bg {
    background: transparent;
    padding: 0;
  }

  del .woocommerce-Price-amount{
    font-size: 1rem;
  }

  &--lg del .woocommerce-Price-amount{
    font-size: 1.4rem;
    font-weight: bold;
  }

  &--lg {
    line-height: 1;
    font-size: 2rem;
  }
}