.catalog-map {
  position: relative;

  &__title {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--base-border-color);
    font-size: 1.5rem;
    line-height: 1;
    color: #000;
  }

  &__inner {
    position: relative;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 27px;
    height: 60px;
    background-color: var(--panels-bg-color);
    border: 1px solid var(--base-border-color);
    text-align: center;
    cursor: pointer;
    font-size: 2rem;

    svg {
      display: none;
    }

    &:before {
      @include absolute-center-translate;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }

    &--left {
      left: 0;
      &:before {
        content: "\f104";
      }
    }
    &--right {
      right: 0;
      &:before {
        content: "\f105";
      }
    }
  }
}