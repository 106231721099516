.benefits {

  &__ico{
  }

  &__desc {
    font-size: 0.9rem;
  }

  &__row {
    padding: 0;
  }

  &__ico {
    color: var(--theme-main-color);
    box-shadow: none;
    border: 1px solid var(--base-border-color);
    width: 64px;
    height: 64px;
    border-radius: 0;
  }

  &__title {
    font-size: 1.2rem;
  }

  &__inner {
    padding: 0 .7rem;
  }
}