.product-search {

  &__submit {

    &,
    &:focus,
    &:active,
    &:hover {
      background: var(--header-search-btn-bg);
      border-color: var(--header-search-btn-bg);
    }
  }

  &__submit-icon {
    color: #fff;
    width: 17px;
    height: 15px;
    &:hover {

    }
  }
}