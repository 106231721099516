.pc-add-to-cart-loop {
  vertical-align: middle;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;

  & > * {
    padding: 0;
    margin-left: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &__button {

  }

  &.button {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  @at-root .product-kit &{
    margin-right: 0;
    margin-top: 0;
  }

}