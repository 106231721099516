.list-nav {

  &__link:hover,
  &__link {
    text-decoration: underline;
  }

  &__item--active &__link {
    color: var(--headline-link-color-hover);
  }
}