$seo-text-title-color:          #000;

.seo-text{
	padding: 35px 20px;
	background: var(--panels-bg-color);

	&__title{
		font-size: 24px;
		margin-bottom: 20px;
		color: $seo-text-title-color;
	}

	&__desc{

	}
	
	h1 {
		color: #000;
	}

}