.filter{

  &__title {
    color: var(--panels-heading-color);
    text-transform: none;
    font-size: 1.2em;
  }

  &__item {
    padding: 20px;
  }

  &__inner {
    margin-top: 1em;
  }

  &__checkgroup-title {
    color: var(--base-main-link-color);
  }

  //--base-main-link-hover-color
  &__checkgroup-link:hover &__checkgroup-title{
    color: var(--base-main-link-hover-color);
  }

  &__properties-item:not(:last-child) {
    margin-bottom: 10px!important;
  }

  &__checkgroup-control {
    top: -3px;
  }

  &__checkgroup-link:hover &__checkgroup-control:not([disabled]) + &__checkgroup-check {
    border-color: var(--theme-main-color);
  }

  &__checkgroup-control[type='checkbox']:checked + &__checkgroup-check {
    background: var(--theme-main-color);
    border-color: var(--theme-main-color);
  }

  &__checkgroup-control[type='radio']:checked + &__checkgroup-check {
    border-color: var(--theme-main-color);

    &:before {
      background: var(--theme-main-color);
    }
  }

  &__checkgroup-control:checked + &__label-button {
    background: var(--theme-main-color);
    border-color: var(--theme-main-color);
  }
}