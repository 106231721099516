.pc-section-primary {
  border: none;


  &--kit {

  }

  &--kit &__title {
    padding: 15px 0;
    margin: 0 0 18px 0;
    border-bottom: 2px solid var(--theme-main-color);
    display: block;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
  }

  &--kit &__inner {
    padding: 0;
  }



  &__title {
    position: static;
    margin: 0 0 20px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid var(--base-border-color);
    display: block;
    width: 100%;
    vertical-align: baseline;
    font-size: 1.5rem;
    line-height: 1;
    color: #000;
  }

  &__inner {
    padding: 0;
  }

  &__arrow {
    width: 27px;
    height: 60px;
    background-color: var(--panels-bg-color);
    border: 1px solid var(--base-border-color);
    font-size: 2rem;
    color: var(--base-font-color);
    svg {
      display: none;
    }

    &:before {
      @include absolute-center-translate;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }

    &--left {
      left: 0;
      &:before {
        content: "\f104";
      }
    }
    &--right {
      right: 0;
      &:before {
        content: "\f105";
      }
    }
  }
}