.pc-product-purchase {

  &__add-to-cart {
    background: transparent;
    margin-bottom: -14px;
    margin-right: -14px;

    & > * {
      margin-bottom: 14px;
      margin-right: 14px;
    }

    &,
    & > * {
      padding: 0;
    }
  }

  &__body {
    padding-top: 20px;
    border-top: 1px dotted #c5c5c5;

  }

  &__header {
    margin-bottom: 18px;
  }

  &__add-to-cart-item {

    
    .btn-default {
      background: #fff;
      padding: 10px 16px;
      &:hover {
        background: var(--btn-default-hover-bg);
      }
    }
  }
}