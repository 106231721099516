//--------------------------------------------------
//--------------------------------------------------
//Base
//--------------------------------------------------
//--------------------------------------------------

$site-max-width:                          1530px;

// Media queries breakpoints
$screen-xs:      480px;
$screen-sm-min:  768px;
$screen-md-min:  992px;
$screen-lg-min:  1200px;

$screen-xs-max:  ($screen-sm-min - 1) !default;
$screen-sm-max:  ($screen-md-min - 1) !default;
$screen-md-max:  ($screen-lg-min - 1) !default;


$page-sidebar-width: 270px;