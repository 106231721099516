.widget_price_filter {
  font-size: .9rem;

  @media(min-width: $screen-sm-min){
    .widget-sidebar__title {
      color: var(--panels-heading-color);
      text-transform: none;
      font-size: 1.2em;
    }
  }

  .clear .ui-slider-handle {
    width: 22px;
    height: 22px;
    top:  -4px;
    box-shadow: 0 3px 5px rgba(0,0,0,.2);
  }
}