.btn {


  @at-root .product-cut__action-row .btn-default{
    background: #fff;
    padding: 4px 12px;

    &:hover {
      background: var(--btn-default-hover-bg);
    }
  }

  &-default {

  }

  &-default__ico {

    &--wishlist {
      width: 22px;
      height: 21px;
    }
  }
}


.btn-load-more {

  &__icon {
    color: var(--theme-main-color);
  }

}