.content {
  margin-top: 25px;

  &__container {
    max-width: $site-max-width;
  }

  .content__sidebar-item.widget_price_filter {
    margin-bottom: 1px;
  }

  &__header {
    padding-bottom: 0;
    display:         flex;
    flex-direction:  column;
    flex-wrap:       wrap;
    justify-content: space-between;
    margin-bottom:   10px;

    @media (min-width: $screen-lg-min) {
      flex-direction: row;
      margin-bottom:  30px;
      border-bottom:  2px solid var(--theme-main-color);
    }
  }

  &__header-item {
    padding-bottom: 20px;

    @media (min-width: $screen-lg-min) {
      padding-bottom: 10px;

      &:first-child {
        padding-right: 15px;
      }
    }
  }

  &__hinfo {
    color: var(--base-font-color-secondary);
    padding-left: .7em;
    border-left: 1px solid var(--base-border-color);
  }

  &__sidebar--right {
    border: none;
    padding-left: 0;
  }

  @at-root body.single-product &__sidebar--right {
    @media (min-width: $screen-lg-min){
      width: 270px;
      margin-right: 30px;
    }
  }
}