.star-rating {

  &__star {
    margin-left: 1px;
    width: 14px;
    height: 14px;

    &:first-child {
      margin-left: 0;
    }

      .svg-icon {
      stroke-width: 40;

      color: var(--star-empty-color);
      stroke: var(--star-rating-color);
    }

    &--active {
      .svg-icon {
        color: var(--star-rating-color);

      }
    }
  }

}